import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ImgsGallery from "./ImgsGallery";
import Preface from './Preface';

class MainContent extends Component {

  render (){      
    const siteContent = this.props.siteContent ? this.props.siteContent : [];
    return (
    <main>
        <Preface/>
        <Container fluid>
        {siteContent.length > 0 && siteContent.map((item, index)=>(    
        <Row key={index} id={item.link} className='content'>
            {(index%2 === 0 || window.innerWidth < 650) && <Col lg='6' sm='12' className='picture'>
                <div style={{backgroundImage: "url(/"+item.pictures.foto +")",
                backgroundPositionX: item.pictures.backgroundPositionX,
                backgroundPositionY: item.pictures.backgroundPositionY,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'}}>
                </div>  
            </Col>}
            {index < 4 && <Col lg='6' sm='12'>
                    <div  className="description">
                        <div><h1>{item.caption}</h1></div>
                        {item.description.map((description, idx)=>(
                            <p key={idx}>{description}</p>
                        ))}
                    </div>
            </Col>}
           {index === 4 && <Col lg='6' sm='12'>
                <div  className="description">
                    <div><h1>{item.caption}</h1></div>
                    <Row className="floor-description" style={{textAlign: 'center'}}>
                        <Col lg='6'>
                            <h2>{item.description[0]}</h2>
                            {item.description.map((description, idx)=>(
                                idx >= 1 && idx <= 5 && <p key={idx}>{description}</p>
                            ))} 
                        </Col>
                        <Col lg='6' style={{borderLeft: window.innerWidth > 650 ? "white 3px solid" : 'none'}}>
                        <h2>{item.description[6]}</h2>
                            {item.description.map((description, idx)=>(
                                idx >= 7 && <p key={idx}>{description}</p>
                            ))} 
                        </Col>
                    </Row>
                </div>
           </Col>}
           {index === 5 && <Col lg='6' sm='12'>
                <div  className="description">
                    <div><h1>{item.caption}</h1></div>
                    <Row className="" style={{textAlign: 'center'}}>
                        <Col lg='6'>
                        <p></p>
                            {item.description.map((description, idx)=>(
                                idx >= 0 && idx <= 3 && <p key={idx}>{description}</p>
                            ))} 
                        </Col>
                        <Col lg='6' style={{borderLeft: window.innerWidth > 650 ? "white 3px solid" : 'none'}}>
                                <p></p>
                            {item.description.map((description, idx)=>(
                                idx >= 4 && <p key={idx}>{description}</p>
                            ))} 
                        </Col>
                    </Row>
                </div>
           </Col>}
           {index%2 === 1 && window.innerWidth > 650 && <Col lg='6' sm='12' className='picture'>
            
            <div style={{backgroundImage: "url(/"+item.pictures.foto +")", backgroundRepeat: 'no-repeat', backgroundPositionX: item.pictures.backgroundPositionX, backgroundPositionY: item.pictures.backgroundPositionY, backgroundSize: 'cover'}}>

            </div>
              
        </Col>}
        {index === 2 && <Col lg='12' sm='12'>
            <ImgsGallery galleryNumber={0}/>
        </Col>}
        {index === 4 && <Col lg='12' sm='12'>
            <ImgsGallery galleryNumber={1}/>
        </Col>}
        </Row>
        ))} 
        </Container>        
        <div id="kontakt" style={{height: '1px', width: '100%'}}></div><div id="om-oss" style={{height: '1px', width: '100%'}}></div>
    </main>
    )}
}

export default MainContent;