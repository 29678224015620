const siteContent = [
  {
    link: 'oppussing',
    pictures: {foto: '5.jpg' , backgroundPositionX: '50%', backgroundPositionY: '50%'},
    title: 'oppussing og nybygg',
    description: [
    'Skal du bygge bolig eller pusse opp det gamle?',
    'Vi utfører reparasjon og rehabilitering, på gamle hus, skadede bygg, isolering eller arbeide med fasade.',
    'Vi kan hjelpe deg med alt innen oppussing og rehabilitering av din bolig. Fra små oppussingsarbeid som innsetting av nye dører og vinduer, montering av lister, legging av parkett til større oppdrag som totale rehabiliteringer av din bolig.',
    ],
    caption: 'Oppussing av bolig',
  },
  {
    link: 'kjoken',
    pictures: {foto: '6.jpg', backgroundPositionX: '0%', backgroundPositionY: '50%'},
    title: 'Kjøkken',
    description: [
      'Å få kjøkkenet ditt renovert er et av de grunnleggende aspektene for ombygging av huset.',
      'Vi hjelper deg gjerne med oppusse opp ditt gamle kjøkken og å montere nytt kjøkken. Legging av nytt gulv og oppsett av nye fliser. Vi kan også være behjelpelig med både rørlegger og elektriker som står for den tekniske installasjonen på kjøkkenet om dette skulle være nødvendig.',
      'Om du ønsker et kjøkken fra IKEA, Epoq eller fra andre leverandører kan vi hjelpe deg med alt fra forarbeidet til monteringen og ferdigstilling av ditt nye kjøkken.'
    ],
    caption: 'Oppussing av kjøkken',
  },
  {
    link: 'bad',
    pictures: {foto: '15.jpg', backgroundPositionX: '50%', backgroundPositionY: '50%'},
    title: 'Bad',
    description: [
      'Vi har den nødvendige kompetansen innan rehabilitering og oppusing av bad og våtrom.',
      'Som godt etablert totalleverandør av bad og våtrom koordinerer vi tømrere, rørleggere, elektrikere, flisleggere og alle andre som er involvert for å utføre oppussing av baderom.',
      'Det tilbys stadig nye og spennende løsninger innen bad og våtrom. Dette krever at vi kontinuerlig søker å oppdatere vårt personell, dette for å kunne fange opp nye trender og hvilke standard disse løsningene krever. ',
    ],
    caption: 'Oppussing av bad',
  },
  {
    link: 'flis',
    pictures: {foto: '3.jpg', backgroundPositionX: '0%', backgroundPositionY: '60%'},
    title: 'Fliser',
    description: [
      'Skal du flislegge gangen, badet, kjøkkenet eller andre rom?',
      'Vi leverer alt innen fliser til både nybygg og rehabiliteringsprosjekter.',
      'Våre flisleggere har lang erfaring og er dyktige i sitt fag. Vi er opptatt av å levere kvalitet i vårt arbeid. Da er du sikret godt fliser arbeid, som er kvalitetssikret etter ditt bruksbehov og mønster.',
    ],
    caption: 'Flisleggingsarbeid',
  },
  {
    link: 'gulv',
    pictures: {foto: '1.jpg', backgroundPositionX: '0%', backgroundPositionY: '85%'},
    title: 'Parkett',
    description: [
      'Gulvlegging',
      'Lammelgulv',
      'Heltregulv',
      'Mønsterparkett i heltre eller lamell',
      'Plank',
      'Reparasjon og vedlikehold',
      'Gulvsliping',
      'Sliping av parkett',
      'Sliping av heltregulv',
      'Sliping av mønstergulv',
      'Behandling med lakk, olje og farging',
      'Relakkering og vedlikeholdsavtaler',

    ],
    caption: 'Parkett og Tregulv',
  },
  {
    link: 'snekker',
    pictures: {foto: '2.jpg', backgroundPositionX: '0%', backgroundPositionY: '50%'},
    title: 'Snekker',
    description: [
      'Dører og Vinduer',
      'Kjøkken og Garderobe',
      'Gulvlegging og Gulvsliping',
      'Paneler',
      'Vi utfører også mindre tømrerarbeider som terrasser, inngangsparti, bytte av kledning eller andre typer fasadeendringer.  ',
    ],
    caption: 'Snekkerarbeid',
  },
];
const kontakt = [
  'Ved å la oss stå som totalleverandør for ditt prosjekt, stiller vi med prosjektleder og bruker våre samarbeidspartnere aktivt i ditt prosjekt. Under ser du noen av fordelene ved å benytte oss som din totalleverandør for ditt prosjekt:',
  'Du har en part å forholde deg til. Vi tar totalansvaret.',
  'Du slipper å bruke tid på å hente inn tilbud fra flere forskjellige leverandører.',
  'Du får et komplett tilbud fra oss.',
  'Vi stiller med alle nødvendige håndverkstjenester som prosjektet trenger.',
  'Vi står for kontakten og koordineringen av alle håndverkerne.',
  'Kvalitet i arbeidet. Vi er ansvarlig for kvalitetssikring.',
  'Skulle det være klager på prosjektet, så er vi ansvarlig for å rydde opp i det.',
  'Vi bringer prosjektet fra start til mål.',
];

const contactForm = {
  kontaktMain: {
    no: 'Vi er alltid glade for å svare på spørsmålene dine.'
  },
  kontakt: {
      no: ['navn', 'e-post / tlf.', 'telefon', 'melding']
  },
  kontaktRules: {
       no: 'Jeg godtar behandlingen av mine personlige opplysninger gitt i dette forespørselsskjemaet av Vyvo Bygg AS for å kontakte deg og svare på det stilte spørsmålet.'
  },
  thankYouHeader: ['Takk for melding!', 'Vi kontakter deg snart.'],
} ;
const aboutUs = [
  'Bygg med kvalitet',
  'Vyvo Bygg AS er en totalleverandør bygg- og anleggstjenester.',
  'Vi påtar oss små og mellomstore byggeprosjekter for private kunder, borettslag og bedrifter. Vi kan bistå deg gjennom hele byggeprosessen. Fra planlegging og grunnarbeid til mål.',
  'Vi har kompetanse innen de fleste tjenester innen oppussing, rehabilitering og andre byggetjenester. Dette gjelder såvel utvendig som innvendig rehabilitering. Vi utfører også tjenester som muring, flislegging og snekker arbeider i tillegg til at vi samarbeider med rørlegger og elektriker.',                 
];

const imageGalleries = [
  {
    folderName: ['fliser'],
    title: ['', '', '', '',],
    description: ['', '', '', '',],
    photos: [
      [
				{file: 'vyvo_bygg_as_flislegging_01.jpg', fill: 110, x: 0, y: 50},
				{file: 'vyvo_bygg_as_flislegging_02.jpg', fill: 100, x: 50, y: 50},
				{file: 'vyvo_bygg_as_flislegging_03.jpg', fill: 100, x: 50, y: 50},
				{file: 'vyvo_bygg_as_flislegging_04.jpg', fill: 120, x: 70, y: 50},
      ],
    ],
  },
  {
    folderName: ['parkett'],
    title: ['', '', '', '',],
    description: ['', '', '', '',],
    photos: [
      [
        {file: 'vyvo_bygg_as_parkett_01.jpg', fill: 110, x: 0, y: 50},
        {file: 'vyvo_bygg_as_parkett_02.jpg', fill: 100, x: 50, y: 50},
        {file: 'vyvo_bygg_as_parkett_03.jpg', fill: 100, x: 50, y: 50},
        {file: 'vyvo_bygg_as_parkett_04.jpg', fill: 120, x: 70, y: 50},
      ],
    ],
  },   
];

const getSiteContent = function () {
  return siteContent;
}
const getAboutUs = function () {
  return aboutUs;
}
const getGallery = function (galleryNumber) {
  return imageGalleries[galleryNumber];
}

export {getSiteContent, getGallery, getAboutUs, contactForm};